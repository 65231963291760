import React, { useState } from 'react';
import { LuPlus } from 'react-icons/lu';
import { RiMailSendLine } from 'react-icons/ri';
import './PopUphome.css';

const Popup = ({ isOpen, onClose, handleSendAllPages }) => {
  const [email, setEmail] = useState('');

  if (!isOpen) return null;

  return (
    <div className="popUp-container" onClick={onClose}>
      <div className="popup-content" onClick={(e) => e.stopPropagation()}>
        <button className="popup-close" onClick={onClose}>✖</button>
        <h2>Envoyer par mail</h2>

        <div className="button-group-popup">
          <input
            type="email"
            placeholder="Entrer email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <button onClick={() => handleSendAllPages(email)}>Envoyer fiches</button>
        </div>
      </div>
    </div>
  );
};

export default Popup;