// Login.js

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Login.css";
import "./LoadingPage.css";
import { IoIosMail } from "react-icons/io";

function Login() {
  const [username, setUsername] = useState("");
  // const [password, setPassword] = useState("");

  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    try {
      // Send a POST request to the serverless login function
      const response = await fetch('/api/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username }),
      });
  
      if (response.ok) {
        console.log('Logged in successfully');
        navigate('/'); // Navigate to Homepage on successful login
      } else {
        const { error } = await response.json();
        console.error('Failed to log in:', error);
      }
    } catch (error) {
      console.error('Error during login:', error);
    }
  };

  return (
    <div className="total-login ">
      <div className="login-page bg-logo">
        <div className="login-container">
          <form className="login-form" onSubmit={handleSubmit}>
            <div className="txt-login">
              {
                "Entrez votre e-mail pour recevoir vos fiches à la fin de l'atelier:"
              }
            </div>
            <div className="email-flex">
              <div className="icons-bg">
                <IoIosMail className="icons" color="white" size={"10px"} />
              </div>
              <input
                type="text"
                name="username"
                placeholder="Email"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>
            <button id="btn-login" type="submit">
              Login
            </button>
          </form>
        </div>
      </div>
      <footer className="footer-login"></footer>
    </div>
  );
}

export default Login;
