import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./HomePage.css"; // Import the CSS file
import "./LoadingPage.css";
import { RiMailSendLine } from "react-icons/ri";
import { GoTrash } from "react-icons/go";
import { LuPlus } from "react-icons/lu";
import Popup from './PopUphome';
import LoadingPage from "./LoadingPage";

//import { url } from "inspector";

const HomePage = () => {
  const [pages, setPages] = useState([]);
  const navigate = useNavigate();
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  useEffect(() => {
    const savedPages = JSON.parse(localStorage.getItem("pages")) || [];
    setPages(savedPages);
  }, []);

  const createNewPage = () => {
    const newPage = { id: Date.now().toString(), data: {}, name: "" };
    const updatedPages = [...pages, newPage];
    localStorage.setItem("pages", JSON.stringify(updatedPages));
    setPages(updatedPages);
    navigate(`/form/${newPage.id}`);
  };

  const deletePage = (id) => {
    const updatedPages = pages.filter((page) => page.id !== id);
    localStorage.setItem("pages", JSON.stringify(updatedPages));
    setPages(updatedPages);
  };

  const deleteAllForms = () => {
    // Clear the pages array
    const updatedPages = [];

    // Update localStorage
    localStorage.setItem("pages", JSON.stringify(updatedPages));

    // Update the state
    setPages(updatedPages);
  };

  const handleSendAllPages = async (email) => {
    if (!email) {
      alert('Please enter your email before sending the data.');
      return;
    }
  
    // Retrieve all pages from localStorage
    const savedPages = JSON.parse(localStorage.getItem('pages')) || [];
  
    // Prepare the payload with relevant data
    const payload = {
      email, // Include email in the payload
      pages: savedPages.map(page => ({
        id: page.id, // Keep the page ID for identification
        data: {
          selectedOption1: page.data.selectedOption1,
          selectedOption2: page.data.selectedOption2,
          TextAreaContent: page.data.TextAreaContent,
          textAreaComment: page.data.textAreaComment,
          textAreaContentOeil: page.data.textAreaContentOeil,
          selectedColor: page.data.selectedColor,
          date: page.data.date,
          WineName: page.data.WineName,
          acidite: page.data.acidite,
          alcool: page.data.alcool,
          tanins: page.data.tanins,
          volume: page.data.volume,
          longeur_en_bouche: page.data.longeur_en_bouche,
          smileyRating: page.data.smileyRating,
        },
      })),
    };
  
    try {
      // Send the data to the serverless function
      const response = await fetch('/api/save-pages', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });
  
      if (response.ok) {
        alert('All pages and email have been sent successfully!');
      } else {
        alert('Failed to send data.');
      }
    } catch (error) {
      console.error('Error sending data:', error);
      alert('An error occurred while sending data.');
    }
  };


  return (
    <div className="homepage-container">
      {/* <LoadingPage /> */}
      {/* Add this line */}
      <div className="featured-section">
        <h2>MES FICHES DE DÉGUSTATION</h2>
        <div className="icone-main-verre"></div>
        {/* Add your featured content here */}
      </div>
      <div className="create-section">
        <ul>
          {pages.map((page) => (
            <li key={page.id} className="page-item">
              <Link to={`/form/${page.id}`}>
                {page.name || `Page ${page.id}`}
              </Link>
              <GoTrash onClick={() => deletePage(page.id)} />
            </li>
          ))}
        </ul>
      </div>
      <div className="footer-homePage">
        {" "}
        <div className="button-group">
          <div className="btn-rect" onClick={createNewPage}>
            <LuPlus size={40} className="btn-ajouter" />
            <p>Ajouter une fiche</p>
          </div>
          <div className="btn-rect" onClick={deleteAllForms}>
            <RiMailSendLine size={40} className="btn-cloturer" />
            <p>Clôturer les fiches</p>
          </div>
          <div className="btn-rect" onClick={() => setIsPopupOpen(true)}>
            <p>Envoyer par mail</p>
          </div>
        </div>

      {isPopupOpen && (
        <Popup
          isOpen={isPopupOpen}
          onClose={() => setIsPopupOpen(false)}
          handleSendAllPages={handleSendAllPages}
        />
      )}

      </div>
  </div>
  );
};

export default HomePage;
